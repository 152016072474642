import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/apply-job.css'
import { navigate } from 'gatsby'
import Swal from 'sweetalert2'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { CountryDropdown } from 'react-country-region-selector'


class ApplyJob extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        jobId : '',
        jobPosition: '',
        jobDepartment: '',
        jobLocation: '',
        resume: '',
        transcript: '',
        certificate: '',
        phoneNumber: '',
        country: '',
        submit: '',
      }
    }

    setCountry(country){
      this.setState({country: country});
      if (country != ''){
        document.getElementById("country-dropdown").style.backgroundImage = 'url("../images/icon-form-user.png")';
      } else{
        console.log('masuk');
        document.getElementById("country-dropdown").style.backgroundImage = 'url("../images/icon-form-user.png")';
      }
    }

    uploadResume(){
      if(document.getElementById("apply-file-resume").files[0].size > 5120000){
        this.setState({resume: "File is too big!"})
     } else{
        this.setState({resume: document.getElementById("apply-file-resume").files.item(0).name})
     }
    }

    uploadTranscript(){
      if(document.getElementById("apply-file-transcript").files[0].size > 5120000){
        this.setState({transcript: "File is too big!"})
     } else{
        this.setState({transcript: document.getElementById("apply-file-transcript").files.item(0).name})
     }
    }

    uploadCertificate(){
      if(document.getElementById("apply-file-certificate").files[0].size > 5120000){
        this.setState({certificate: "File is too big!"})
     } else{
        this.setState({certificate: document.getElementById("apply-file-certificate").files.item(0).name})
     }
    }

    componentDidMount(){
      if (this.props.location.state!=null) {
        this.setState({jobId: this.props.location.state.jobId})
        this.setState({jobPosition: this.props.location.state.jobPosition})
        this.setState({jobDepartment: this.props.location.state.jobdepartment})
        this.setState({jobLocation: this.props.location.state.jobLocation})
      } else{
        navigate('/careers')
      }
      if (this.props.location.search == '?submit=True'){
        setTimeout(() => {
            Swal.fire({
                title: "<div class='alert-title'>Thank You!</div>",
                imageUrl: 'https://i.ibb.co/x5hFH0C/Group-5.png',
                imageWidth: 35,
                imageAlt: 'tes', 
                html:
                  "<div class='alert-text'>Your application has been sent.<br>" +
                  "We'll get back to you.<br><br></div>",
                background: '#385723',
                width: 450,
                height: 300,
                showCloseButton: true,
                showConfirmButton: false,
                focusConfirm: false,
              });
        }, 0);
      } else if (this.props.location.search == '?submit=False'){
        this.setState({submit: 'false'});
      }
    }

    render(){

        return(

            <Layout>
                <Head title = "Apply Job"/>
                <div id = "apply-main-container">
                    
                    <div id = "apply-title">
                    
                      <div id = "apply-title-container">

                        <div id = "apply-title-primary">Jobs</div>
                        <div id = "apply-title-jobs">
                          <div id = "apply-title-jobs-txt">{this.state.jobDepartment}</div>
                          <div id = "apply-title-jobs-arrow">{">"}</div> 
                          <div id = "apply-title-jobs-txt">{this.state.jobPosition}</div>
                        </div>

                      </div>

                    </div>
                    

                    <div id = "apply-body">

                      <form method="post" action="http://tania-notification-service.neurafarm-services.com/website/v2/careers" enctype="multipart/form-data">

                        <div class = "apply-content">

                            <div id = "position">{this.state.jobPosition}</div>

                            <input name = "position" value = {this.state.jobPosition} id = "apply-form-position"/>

                            <div id = "info">
                                
                              <div id = "job-info">
                                  <div>
                                    <img class = "item-icon" src = "https://i.ibb.co/RbCN9Jv/work-2.png" alt = "department-img"/>
                                    <span class = "item-info">{this.state.jobDepartment}</span>
                                  </div>
                                  <div>
                                    <img class = "item-icon" src = "https://i.ibb.co/3dH5FsW/pin-2.png" alt = "location-img"/>
                                    <span class = "item-info">{this.state.jobLocation}</span>
                                  </div>
                              </div>

                              <div class = "required">
                                *Required
                              </div>
                            
                            </div>

                            <div id = "apply-resume">
                              <div class = "apply-section-title">Resume<span class = "required">*</span></div>
                              <div class = "apply-section-subtitle">Upload your resume in English (5 MB max).</div>
                              <label for = "apply-file-resume" class = "apply-file-input-field">
                                  Select File
                              </label>
                              <input id = "apply-file-resume"
                                  type = "file" 
                                  name = "resume"
                                  onChange = {() => this.uploadResume()}/>
                              <span class = "apply-file-name">
                                {this.state.resume}
                              </span>
                            </div>

                            <div id = "apply-contact-detail">
                              <div class = "apply-section-title">Contact Details</div>
                              <div class = "apply-section-subtitle">Your legal name is the name that appears on your official documents, such as your driver's license or passport.</div>
                              
                              <div id = "apply-contact-form-container">
                                
                                <input class = "apply-input-field" 
                                  type = "text" 
                                  name = "legalName"
                                  placeholder = "       Legal Name*"
                                  id = "apply-form-legal-name" required/>

                                <input class = "apply-input-field" 
                                  type = "text" 
                                  name = "preferredName"
                                  placeholder = "       Preferred Name"
                                  id = "apply-form-preferred-name"/>

                                <input class = "apply-input-field" 
                                  type = "email" 
                                  name = "emailAddress"
                                  placeholder = "       Email Address*"
                                  id = "apply-form-email-address" required/>

                                <input class = "apply-input-field" 
                                  type = "text" 
                                  name = "address"
                                  placeholder = "       Address"
                                  id = "apply-form-address"/>

                                <div class = "half-field-container">

                                  <div class = "apply-input-field" id = "apply-form-country">
                                    <CountryDropdown
                                      id = "country-dropdown"
                                      name = "country"
                                      defaultOptionLabel = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country*"
                                      value = {this.state.country}
                                      onChange={(country) => this.setCountry(country)} required/>
                                  </div>

                                  <input class = "apply-input-field" 
                                    type = "text" 
                                    name = "city"
                                    placeholder = "       City*"
                                    id = "apply-form-city" required/>

                                </div>

                                <div class = "half-field-container">

                                  <input class = "apply-input-field" 
                                    type = "text" 
                                    name = "province"
                                    placeholder = "       State/Province"
                                    id = "apply-form-state"/>

                                  <input class = "apply-input-field" 
                                    type = "number" 
                                    name = "postalCode"
                                    placeholder = "       Zip/Postal Code"
                                    id = "apply-form-zip"/>

                                </div>

                                <PhoneInput
                                  placeholder = "       Phone Number"
                                  name = "phoneNumber"
                                  value = {this.state.phoneNumber}
                                  defaultCountry="ID"
                                  onChange = {phone => this.setState({phone})}/>

                                <input class = "apply-input-field" 
                                  type = "text" 
                                  name = "personalWebsite"
                                  placeholder = "       Personal Website"
                                  id = "apply-form-website"/>

                              </div>
                            
                            </div>
                        
                        </div>

                        <div class = "apply-content">

                          <div id = "apply-supporting-document">

                            <div class = "apply-section-title">Supporting Documents & Information</div>  
                            <input class = "apply-input-field" 
                              type = "text" 
                              name = "portofolio"
                              placeholder = "       Link to your portofolio or other document"
                              id = "apply-form-portofolio"/>
                            <div class = "apply-section-subtitle">Additional document such as Transcript, Certificates, Portofilio (Max. 5MB each)</div>

                            <div class = "apply-section-title-2">Transcript</div>
                            <label for = "apply-file-transcript" class = "apply-file-input-field">
                              Select File
                            </label>
                            <input id = "apply-file-transcript"
                              type = "file" 
                              name = "transcript"
                              onChange = {() => this.uploadTranscript()}/>
                            <span class = "apply-file-name">
                              {this.state.transcript}
                            </span>
                            
                            <div class = "apply-section-title-2">Certificates</div>
                            <label for = "apply-file-certificate" class = "apply-file-input-field">
                              Select File
                            </label>
                            <input id = "apply-file-certificate"
                              type = "file" 
                              name = "certificates"
                              onChange = {() => this.uploadCertificate()}/>
                            <span class = "apply-file-name">
                              {this.state.certificate}
                            </span>

                          </div>
                        
                        </div>

                        {(this.state.resume != "File is too big!" && this.state.transcript != "File is too big!" && this.state.certificate != "File is too big!") && <input 
                          type = "submit" 
                          value = "Apply Now"
                          onClick = "submit();" 
                          id = "apply-form-submit"/>
                        }

                        {(this.state.resume == "File is too big!" || this.state.transcript == "File is too big!" || this.state.certificate == "File is too big!") && <div>
                            <div id = "apply-form-unsubmit">Apply Now</div>
                            <div id="apply-error-msg">Please, check your input!</div>
                          </div>
                        }

                        {this.state.submit==='false' && <div id="apply-error-msg"> We are unable to send your application. Try again later</div>}

                      </form>

                    </div>

                </div>
            </Layout>
        
        );
    }
}

export default ApplyJob;
