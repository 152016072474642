import React from 'react'
import {Link} from 'gatsby'
import {Navbar, Nav, NavDropdown, Dropdown} from 'react-bootstrap';
import { globalHistory } from "@reach/router"

//import ReadingProgress from 'react-reading-progress'

import '../styles/header.css'
import neurafarm from "../images/Neurafarm-Logo-Samping.png"
import logoInternet from "../images/internet.png"


/* 
<Link className = "link-container" to="/">
                                What We Do 
                                <span style = {{display : this.state.url !== `${baseUrl}we-do` ? "none" : "inline-block"}}
                                    className = "active-nav" />
                            </Link>
                            
                            <Link className = "link-container" to="/" >
                                Who We Are 
                                <span style = {{display : this.state.url !== `${baseUrl}we-are` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/dokter-tania">
                                Dokter Tania
                                <span style = {{display : this.state.url !== `${baseUrl}dokter-tania` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/">
                                Blog
                                <span style = {{display : this.state.url !== `${baseUrl}nav4` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>
                            
                            <Link className = "link-container" to="/">
                                Contact Us
                                <span style = {{display : this.state.url !== `${baseUrl}nav5` ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>
*/

//const baseUrl = typeof window !== 'undefined' ? window.location.href : '';

class Header extends React.Component {

    /*constructor(props){
        super(props);
    }*/

    render(){
        return(
                <Navbar id = "navbar-container" collapseOnSelect expand="md" variant="light" fixed = "top">
                    
                    <Navbar.Brand>
                        <Link to ="/">
                            <img className = "neurafarm-logo" src = {neurafarm} alt = "Neurafarm" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Link className = "link-container" to="/what-we-do">
                                What We Do 
                                <span style = {{display : globalHistory.location.pathname !== "/what-we-do" ? "none" : "inline-block"}}
                                    className = "active-nav" />
                            </Link>
                            
                            <Link className = "link-container" to="/who-we-are" >
                                Who We Are 
                                <span style = {{display : globalHistory.location.pathname !== "/who-we-are" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/dokter-tania">
                                Dokter Tania
                                <span style = {{display : globalHistory.location.pathname !== "/dokter-tania" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/blog">
                                Blog
                                <span style = {{display : globalHistory.location.pathname.includes("/blog") ?  "inline-block" : "none"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/community">
                                Community
                                <span style = {{display : globalHistory.location.pathname !== "/community" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                            <Link className = "link-container" to="/careers">
                                Join Us
                                <span style = {{display : globalHistory.location.pathname !== "/careers" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>
                            
                            <Link className = "link-container" to="/contact-us">
                                Contact Us
                                <span style = {{display : globalHistory.location.pathname !== "/contact-us" ? "none" : "inline-block"}}
                                    className = "active-nav"/>
                            </Link>

                        </Nav>
                        <img className = "language-logo" src = {logoInternet} alt = "internet-logo" />
                        <NavDropdown title="English">                
                            <NavDropdown.Item >
                                <Link className = "language-item">
                                    English                        
                                    <div className = "active-circle"></div>
                                </Link>
                            </NavDropdown.Item>
                            
                            <Dropdown.Divider />
                            
                            <NavDropdown.Item >
                                <Link
                                    to = {globalHistory.location.pathname.includes("/blog") ? `${globalHistory.location.pathname}` : `/id${globalHistory.location.pathname}`} 
                                    className = "language-item">Indonesia
                                </Link>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar>
        )
    }
}

export default Header;